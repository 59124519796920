<template>
  <div
    class="test-equipment px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Test Equipment" size="large" />
    <img
      src="@/assets/homeslide3-min.jpg"
      width="1700"
      height="564"
      alt="smstestequipment"
      title="SMS Test Equipment"
      class="img-responsive wp-image-11751"
    />
    <div class="flex flex-wrap mt-10">
      <div class="w-full md:w-1/3 mb-6 md:pr-2">
        <TitleComponent title="SMS Equipment" />
        <PanelGroupComponent :panels="panels" />
      </div>
      <div class="w-full md:w-2/3 mb-6 md:pl-2">
        <Paragraph
          ><p>
            Safety Management Services, Inc. (SMS™) manufactures energetic
            testing equipment for clients who desire the in-house capability to
            conduct Explosives Sensitivity Testing, In-Process Simulation, or
            DOT Classification Testing. The SMS test equipment is manufactured
            at our machine shop in Salt Lake City, Utah where assembly, QA
            testing, and certification of each piece of equipment is completed
            prior to shipment. Because SMS controls many of the original design
            specifications we have the ability to customize each piece of test
            equipment to meet customer needs while maintaining the integrity and
            intent of the original equipment design. SMS also manufactures
            shipping containers under the Department of Transportation Exemption
            SP8451 and SP13481 that are used for transporting many of the
            energetic test samples to and from the testing facilities. The test
            equipment price includes an on-site training course that includes an
            overview of equipment components, features, operation, and safety
            guidelines. SMS is committed to providing our customers with the
            highest quality equipment on the market and as part of that
            commitment we provide on-going customer support. Customers that
            purchase SMS test equipment also gain membership to the ET Users’
            Group and all the resources available to its members. This includes
            industry standardized procedures, lab certification, test methods
            matrix, and yearly meetings discussing the issues and concerns of
            the industry with the world’s leading explosives experts.
          </p>
          <h3
            data-fontsize="16"
            data-lineheight="24px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:16; line-height: 1.5; --minFontSize:16;"
          >
            ABL Test Equipment Heritage
          </h3>
          <p>
            The SMS ABL Friction Test Machine, the SMS ABL Electrostatic
            Discharge (ESD) Machine, and the SMS Modified Bureau of Mines (MBOM)
            Impact Test Machine were developed, designed, and fabricated by
            Hercules Inc. at the Allegany Ballistics Laboratory (ABL) in Rocket
            Center West Virginia to simulate in-process conditions associated
            with propellant, explosives, and pyrotechnic (PEP) material
            manufacturing. These devices have been successfully used to
            characterize the sensitivity of various liquids, solids and powders
            associated with PEP manufacturing. The energetic sensitivity data
            has been used to facilitate safe design specifications for a wide
            variety of processes and systems. Alliant Techsystems purchased
            Hercules Inc. in 1995 and thus acquired all rights and intellectual
            property associated with these sensitivity test devices. In 2001
            Safety Management Services, Inc. obtained an exclusive license to
            all the intellectual property associated with these test devices
            from Alliant Techsystems (ATK). In addition to complete access to
            all design specifications and developmental literature, the license
            grants SMS with the ability to improve these devices and enhance the
            ATK intellectual property. In 2012 ATK expanded the exclusive
            license to include the SMS Simulated Bulk Autoignition Temperature
            (SBAT) Apparatus and Volume Resistivity Test Cell.
          </p>
          <p>
            Please contact us by using the chat window at the bottom right of
            the screen, calling 801-567-0456, or filling out the
            <router-link class="text-red-800 link" to="/contact"
              >form here.</router-link
            >
          </p>
        </Paragraph>
      </div>
    </div>
    <div class="pt-8 flex flex-wrap image-row-link">
      <div class="w-full md:w-1/4">
        <div
          class="fusion-column-wrapper p-4"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <span
            style="-webkit-box-shadow: 3px 3px 7px rgba(0,0,0,0.3);box-shadow: 3px 3px 7px rgba(0,0,0,0.3);"
            class="fusion-imageframe imageframe-dropshadow imageframe-4 hover-type-none"
            ><router-link
              class="fusion-no-lightbox"
              to="/test-equipment/abl-esd"
              ><img
                src="@/assets/TestEquipment/esd-needle-min.jpg"
                width="242"
                height="155"
                alt=""
                class="img-responsive"/></router-link
          ></span>
          <div class="fusion-sep-clear"></div>
          <div
            class="fusion-separator fusion-full-width-sep sep-none"
            style="margin-left: auto;margin-right: auto;margin-top:10px;margin-bottom:10px;"
          ></div>
          <div class="fusion-text">
            <h2
              data-fontsize="18"
              data-lineheight="27px"
              class="fusion-responsive-typography-calculated text-left"
              style="font-size:18px; line-height: 1.5; --minFontSize:18;"
            >
              Electrostatic Discharge (ESD)
            </h2>
          </div>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
      <div class="w-full md:w-1/4">
        <div
          class="fusion-column-wrapper p-4"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <span
            style="-webkit-box-shadow: 3px 3px 7px rgba(0,0,0,0.3);box-shadow: 3px 3px 7px rgba(0,0,0,0.3);"
            class="fusion-imageframe imageframe-dropshadow imageframe-4 hover-type-none"
            ><router-link
              class="fusion-no-lightbox"
              to="/test-equipment/abl-friction"
              ><img
                src="@/assets/TestEquipment/friction-min.jpg"
                width="242"
                height="155"
                alt=""
                class="img-responsive"/></router-link
          ></span>
          <div class="fusion-sep-clear"></div>
          <div
            class="fusion-separator fusion-full-width-sep sep-none"
            style="margin-left: auto;margin-right: auto;margin-top:10px;margin-bottom:10px;"
          ></div>
          <div class="fusion-text">
            <h2
              data-fontsize="18"
              data-lineheight="27px"
              class="fusion-responsive-typography-calculated text-left"
              style="font-size:18px; line-height: 1.5; --minFontSize:18;"
            >
              ABL Friction
            </h2>
          </div>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
      <div class="w-full md:w-1/4">
        <div
          class="fusion-column-wrapper p-4"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <span
            style="-webkit-box-shadow: 3px 3px 7px rgba(0,0,0,0.3);box-shadow: 3px 3px 7px rgba(0,0,0,0.3);"
            class="fusion-imageframe imageframe-dropshadow imageframe-5 hover-type-none"
            ><router-link
              class="fusion-no-lightbox"
              to="/test-equipment/mbom-type-12-and-boe-impact"
              ><img
                src="@/assets/TestEquipment/impact-min.jpg"
                width="242"
                height="155"
                alt="Impact"
                class="img-responsive"/></router-link
          ></span>
          <div class="fusion-sep-clear"></div>
          <div
            class="fusion-separator fusion-full-width-sep sep-none"
            style="margin-left: auto;margin-right: auto;margin-top:10px;margin-bottom:10px;"
          ></div>
          <div class="fusion-text">
            <h2
              data-fontsize="18"
              data-lineheight="27px"
              class="fusion-responsive-typography-calculated text-left"
              style="font-size:18px; line-height: 1.5; --minFontSize:18;"
            >
              Impact
            </h2>
          </div>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
      <div class="w-full md:w-1/4">
        <div
          class="fusion-column-wrapper p-4"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <span
            style="-webkit-box-shadow: 3px 3px 7px rgba(0,0,0,0.3);box-shadow: 3px 3px 7px rgba(0,0,0,0.3);"
            class="fusion-imageframe imageframe-dropshadow imageframe-5 hover-type-none"
            ><router-link
              class="fusion-no-lightbox"
              to="/test-equipment/godetect"
              ><img
                src="@/assets/TestEquipment/godetect-min.jpg"
                width="242"
                height="155"
                alt="Facility Siting and Design"
                class="img-responsive wp-image-12294"/></router-link
          ></span>
          <div class="fusion-sep-clear"></div>
          <div
            class="fusion-separator fusion-full-width-sep sep-none"
            style="margin-left: auto;margin-right: auto;margin-top:10px;margin-bottom:10px;"
          ></div>
          <div class="fusion-text">
            <h2
              data-fontsize="18"
              data-lineheight="27px"
              class="fusion-responsive-typography-calculated text-left"
              style="font-size:18px; line-height: 1.5; --minFontSize:18;"
            >
              GoDetect
            </h2>
          </div>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";
import PanelGroupComponent from "@/components/PanelGroupComponent";

export default {
  metaInfo: {
    title: "Testing Equipment",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS manufacturers ABL energetic testing equipment, including the SMS ABL Friction Test Machine, the SMS ABL Electrostatic Discharge (ESD) Machine, and the SMS Modified Bureau of Mines (MBOM) Impact Test Machine."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph,
    PanelGroupComponent
  },
  data: () => {
    return {
      panels: [
        {
          contentLink: {
            text: "Learn More",
            to: "/test-equipment/abl-friction",
            button: true
          },
          title: "SMS ABL Friction Machine"
        },
        {
          contentLink: {
            text: "Learn More",
            to: "/test-equipment/mbom-type-12-and-boe-impact",
            button: true
          },
          title: "SMS MBOM Impact Machine"
        },
        {
          contentLink: {
            text: "Learn More",
            to: "/test-equipment/abl-esd",
            button: true
          },
          title: "SMS ABL ESD Machine"
        },
        {
          contentLink: {
            text: "Learn More",
            to: "/test-equipment/godetect",
            button: true
          },
          title: "GoDetect"
        },
        {
          contentLink: {
            text: "Learn More",
            to: "/test-equipment/sbat",
            button: true
          },
          title: "SMS SBAT Apparatus"
        },
        {
          contentLink: {
            text: "Learn More",
            to: "/koenen",
            button: true
          },
          title: "Koenen"
        },
        {
          contentLink: {
            text: "Learn More",
            to: "/test-equipment/time-pressure-apparatus",
            button: true
          },
          title: "Time-Pressure Apparatus"
        },
        {
          contentLink: {
            text: "Learn More",
            to: "/test-equipment/impingement-guns",
            button: true
          },
          title: "Impingement Guns"
        },
        {
          contentLink: {
            text: "Learn More",
            to: "/test-equipment/shipping-containers",
            button: true
          },
          title: "Shipping Containers"
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.test-equipment {
  h3 {
    font-size: 16px;
    color: black;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  font-size: 15px;
}
</style>
